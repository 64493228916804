<template>
  <div class="broker-menus-register">
    <div class="container-broker-menu">
      <div class="broker-menus-register-content">
        <div class="broker-menus-register-logo">
          <router-link :to="{ name: 'broker-menus' }">
            <img
              class="broker-menus-register-logo-desktop"
              src="../../../assets/broker-menu/logo.svg"
              alt=""
            />
            <img
              class="broker-menus-register-logo-mb"
              src="../../../assets/broker-menu/logo-mb.svg"
              alt=""
            />
          </router-link>
        </div>

        <div class="broker-menus-register-content-form-container">
          <div class="broker-menus-register-content-form-container-blocks-img">
            <img
              class="broker-menus-register-content-form-container-blocks-img-desktop"
              src="../../../assets/broker-menu/table-bg-img.svg"
              alt=""
            />
            <img
              class="broker-menus-register-content-form-container-blocks-img-mb"
              src="../../../assets/broker-menu/table-bg-img-mb.svg"
              alt=""
            />
          </div>
          <div class="broker-menus-register-content-form-container-blocks-img2">
            <img
              class="broker-menus-register-content-form-container-blocks-img-desktop"
              src="../../../assets/broker-menu/table-bg-img2.svg"
              alt=""
            />
            <img
              class="broker-menus-register-content-form-container-blocks-img-mb"
              src="../../../assets/broker-menu/table-bg-img-mb.svg"
              alt=""
            />
          </div>

          <div class="broker-menus-register-content-form-left-side">
            <img
              src="../../../assets/broker-menu/register/form-left-img.svg"
              alt=""
            />
          </div>
          <div class="broker-menus-register-content-form-right-side">
            <!-- <div class="broker-menus-register-content-form-right-side-logo">
              <img :src="brokerLogo" alt="" />
            </div> -->

            <!-- <div class="broker-menus-register-content-form-right-side-content">
              <h3>Register Now!</h3>

              <p>
                Fill out your details and a {{ brokerName }} trading consultant
                will be in touch soon to help you.
              </p>
            </div> -->

            <div class="broker-menus-register-content-form-right-side-iframe">
              <!-- <div v-if="loader" class="loader-img">
                <img src="../../../assets/broker-menu/loader.gif" alt="" />
              </div> -->

              <!-- <div v-if="!loader" class="broker-name-from-state">
               
              </div> -->
              <!-- <iframe
                @load="loader = false"
                v-resize="{
                  inPageLinks: true,
                  heightCalculationMethod: 'grow',
                }"
                id="iFrameResizer"
                :src="`https://blog.leaninvestor.io/forms/external-serious-leads-quiz-filter-red?broker_name=${brokerName}`"
                width="100%"
                frameborder="0"
              ></iframe> -->
              <form class="broker-menu-form" v-if="!formIsComplete">
                <div class="broker-menu-form-broker-img">
                  <img :src="`/table-icons/${brokerName}.png`" alt="" />
                </div>

                <div class="broker-menu-form-header">
                  <h3>Register Now!</h3>
                </div>

                <div class="broker-menu-form-header-sub">
                  <p>
                    Fill out your details and a {{ brokerName }} trading
                    consultant will be in touch soon to help you.
                  </p>
                </div>

                <div class="broker-menu-form-inputs">
                  <div class="broker-menu-form-inputs-firstName-lastName">
                    <input
                      placeholder="First name"
                      type="text"
                      v-model="userData.first_name"
                    />

                    <transition name="fade">
                      <div
                        v-if="firstNameError"
                        class="broker-menu-form-inputs-firstName-lastName-error firstName"
                      >
                        <p>No numbers or symbol, min 3 characters</p>
                      </div>
                    </transition>

                    <input
                      placeholder="Last name"
                      type="text"
                      v-model="userData.last_name"
                    />

                    <transition name="fade">
                      <div
                        v-if="lastNameError"
                        class="broker-menu-form-inputs-firstName-lastName-error lastName"
                      >
                        <p>No numbers or symbol, min 3 characters</p>
                      </div>
                    </transition>
                  </div>

                  <div class="broker-menu-form-inputs-email">
                    <input
                      placeholder="Email"
                      type="email"
                      v-model="userData.email"
                    />
                    <transition name="fade">
                      <div
                        v-if="emailError"
                        class="broker-menu-form-inputs-email-error"
                      >
                        <p>Please provide valid email address</p>
                      </div>
                    </transition>
                  </div>
                </div>
                <div class="broker-menu-form-inputs-phone">
                  <VuePhoneNumberInput
                    no-use-browser-locale
                    fetch-country
                    show-code-on-list
                    @update="
                      [
                        (dataFromPhoneInput = $event),
                        (phoneCode = $event.countryCallingCode),
                        (phoneWithIso = $event.formattedNumber),
                        (isPhoneNumberValid = $event.isValid)(
                          (location = $event.countryCode)
                        ),
                      ]
                    "
                    :ignored-countries="['IR']"
                    v-model="userData.phone"
                  />
                  <transition name="fade">
                    <div
                      v-if="phoneError"
                      class="broker-menu-form-inputs-phone-error"
                    >
                      <p>Please provide valid phone number</p>
                    </div>
                  </transition>
                </div>

                <div class="broker-menu-form-inputs-submit">
                  <button
                    :disabled="isSubmitBtnDisabled"
                    @click.prevent="submitForm"
                  >
                    <p v-if="!isSubmitBtnDisabled">Submit</p>
                    <img
                      style="
                        width: 50px;
                        width: 100%;
                        height: 100%;
                        border-radius: 28px;
                        object-fit: cover;
                      "
                      v-if="isSubmitBtnDisabled"
                      src="../../../assets/broker-menu/loader.gif"
                      alt=""
                    />
                  </button>
                </div>

                <div v-if="errorFromServer" class="errorFromServer">
                  <p>{{ errorPFromServer }}</p>
                </div>
              </form>

              <div class="broker-menu-step-2" v-if="formIsComplete">
                <div class="broker-menu-step-2-header-top">
                  <h3>Registration completed successfully</h3>
                </div>
                <div class="broker-menu-step-2-header">
                  <h3>Your personal account manager will call you shortly</h3>
                </div>

                <div class="broker-menu-step-2-img">
                  <img
                    src="../../../assets/broker-menu/broker-menu-from-step-2.svg"
                    alt=""
                  />
                </div>

                <div class="broker-menu-step-2-header-sub">
                  <p>To start trading now</p>
                </div>

                <div class="broker-menu-step-2-btn">
                  <a :href="brokerFinalHrefWithToken">Visit Broker</a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import VuePhoneNumberInput from "vue-phone-number-input";
import "vue-phone-number-input/dist/vue-phone-number-input.css";
import { mapGetters } from "vuex";
export default {
  data() {
    return {
      formIsComplete: false,
      queryFromUrl: this.$route.query,
      isSubmitBtnDisabled: false,
      brokerUrlLink: "",
      brokerToken: "",
      brokerFinalHrefWithToken: "",
      errorPFromServer: "",
      errorFromServer: false,
      dataFromPhoneInput: "",
      loader: true,
      brokerName: "",
      brokerLogo: "",
      brokerEncode: "",
      isPhoneNumberValid: "",
      phoneFullNumner: "",
      errors: false,
      firstNameError: false,
      firstNameErrorPass: true,
      lastNameError: false,
      lastNameErrorPass: true,
      emailError: false,
      emailErrorPass: true,
      phoneError: false,
      phoneErrorPass: true,
      phoneWithIso: "",
      phoneCode: "",
      location: "",

      userData: {
        offer_id: this.getParams("offer_id"),
        aff_id: this.getParams("aff_id"),
        first_name: "",
        last_name: "",
        email: "",
        password: "",
        area_code: "",
        phone: "",
        offer_description_override: this.getParams(
          "offer_description_override"
        ),
        country: "",
        iso: "",
        aff_sub: this.getParams("aff_sub"),
        aff_sub1: this.getParams("aff_sub1"),
        aff_sub2: this.getParams("aff_sub2"),
        aff_sub3: this.getParams("aff_sub3"),
        aff_sub4: this.getParams("aff_sub4"),
        aff_sub5: this.getParams("aff_sub5"),
        ip: "",
        broker: localStorage.brokerName,
      },
    };
  },

  components: {
    VuePhoneNumberInput,
  },

  methods: {
    ...mapGetters(["getBrokerName", "getBrokerLogo"]),

    getParams(param) {
      let urlParam = new URLSearchParams(location.search);
      return urlParam.get(param);
    },

    submitForm() {
      if (
        this.hasNumbers(this.userData.first_name) ||
        !this.userData.first_name ||
        this.hasSymbole(this.userData.first_name) ||
        this.userData.first_name.length < 3
      ) {
        this.firstNameError = true;
        this.firstNameErrorPass = false;
        setTimeout(() => {
          this.firstNameError = false;
        }, 3000);
      } else if (
        !this.hasNumbers(this.userData.first_name) ||
        this.userData.first_name ||
        this.hasSymbole(!this.userData.first_name) ||
        this.userData.first_name.length >= 3
      ) {
        this.firstNameErrorPass = true;
      }
      if (
        this.hasNumbers(this.userData.last_name) ||
        !this.userData.last_name ||
        this.hasSymbole(this.userData.last_name) ||
        this.userData.last_name.length < 3
      ) {
        this.lastNameError = true;
        this.lastNameErrorPass = false;
        setTimeout(() => {
          this.lastNameError = false;
        }, 3000);
      } else if (
        this.hasNumbers(!this.userData.last_name) ||
        this.userData.last_name ||
        this.hasSymbole(!this.userData.last_name) ||
        this.userData.last_name.length >= 3
      ) {
        this.lastNameErrorPass = true;
      }

      if (
        !this.validateEmailTest(this.userData.email) ||
        !this.userData.email
      ) {
        this.errors = true;
        this.emailErrorPass = false;
        this.emailError = true;
        setTimeout(() => {
          this.emailError = false;
        }, 3000);
      } else if (
        this.validateEmailTest(this.userData.email) ||
        this.userData.email
      ) {
        this.emailErrorPass = true;
      }

      if (!this.isPhoneNumberValid) {
        this.phoneError = true;
        this.phoneErrorPass = false;
        setTimeout(() => {
          this.phoneError = false;
        }, 3000);
      } else if (this.isPhoneNumberValid) {
        this.phoneErrorPass = true;
      }

      if (
        this.phoneErrorPass &&
        this.emailErrorPass &&
        this.lastNameErrorPass &&
        this.firstNameErrorPass
      ) {
        this.userData.phone = this.phoneWithIso;
        this.userData.area_code = this.phoneCode;
        this.userData.location = this.location;
        this.isSubmitBtnDisabled = true;
        this.sendData();
      }
    },

    sendData() {
      const headers = {
        "Content-Type": "application/json",
      };
      this.axios
        .post("//trafficon-api.com/secured-registration", this.userData, {
          headers,
        })
        .then((response) => {
          if (response.data.message) {
            this.errorPFromServer = response.data.message;
            this.errorFromServer = true;
            this.isSubmitBtnDisabled = false;
          } else {
            this.isSubmitBtnDisabled = false;
            this.brokerUrlLink = response.data.ref_link;
            this.token = response.data.token;
            this.brokerFinalHrefWithToken = `${response.data.ref_link}${response.data.token}`;
            console.log(this.brokerUrlLink, this.token);
            this.formIsComplete = true;
          }
        })
        .catch((error) => {
          console.log("There was an error!", error);
        });
    },
    hasNumbers(t) {
      let regex = /\d/g;
      return regex.test(t);
    },

    hasSymbole(t) {
      let regex = /[ `!@#$%^&*()_+\-=[\]{};':"\\|,.<>?~]/;
      return regex.test(t);
    },

    validateEmailTest(email) {
      const re = /^(([^<>()\]\\.,;:\s@"]+(\.[^<>()\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return re.test(String(email).toLowerCase());
    },

    randomPassword(length) {
      var result = "Aa1";
      var characters =
        "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
      var charactersLength = characters.length;
      for (var i = 0; i < length - 3; i++) {
        result += characters.charAt(
          Math.floor(Math.random() * charactersLength)
        );
      }
      return (this.password = result);
    },

    codeUrl() {
      this.brokerEncode = encodeURIComponent(this.brokerLogo);
    },
  },

  created() {
    this.userData.password = this.randomPassword(10);
  },

  mounted() {
    this.brokerName = this.getBrokerName();
    this.brokerLogo = this.getBrokerLogo();
    this.axios
      .get("https://geol.startbundlingnow.com/geolocate")
      .then((res) => {
        this.userData.country = res.data.result.country;
        this.userData.ip = res.data.result.ip;
        this.userData.iso = res.data.result.iso;
      });
    this.codeUrl();

    if (localStorage.brokerName) {
      this.brokerName = localStorage.brokerName;
    }

    if (localStorage.brokerEncode) {
      this.brokerEncode = localStorage.brokerEncode;
    }
  },

  watch: {
    brokerName(newbrokerName) {
      localStorage.brokerName = newbrokerName;
    },

    brokerEncode(newbrokerEncode) {
      localStorage.brokerEncode = newbrokerEncode;
    },
  },
};
</script>

<style>
.broker-menu-step-2 {
  text-align: center;
}
.broker-menu-step-2-img {
  display: flex;
  justify-content: center;
}

.broker-menu-step-2-header-top h3 {
  font-size: 21px;
  font-weight: lighter;
  margin-bottom: 15px;
}

.broker-menu-step-2-header h3 {
  font-size: 38px;
  font-weight: bold;
  margin-bottom: 20px;
}

.broker-menu-step-2-header-sub p {
  font-size: 21px;
  margin-top: 30px;
}

.broker-menu-step-2-btn {
  background: #e94560;
  height: 60px;
  max-width: 274px;
  width: 100%;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50px;
  margin-top: 20px;
}

.broker-menu-step-2-btn a {
  color: #fff;
  font-size: 21px;
}

@media (max-width: 1024px) {
  .broker-menu-step-2-header h3 {
    font-size: 30px;
  }
}
@media (max-width: 500px) {
  .broker-menu-step-2-header h3 {
    font-size: 24px;
  }

  .broker-menu-step-2-img img {
    width: 220px;
  }
}

.broker-menu-form-inputs-firstName-lastName-error.lastName {
  right: 18px;
}
.broker-menu-form input::placeholder {
  color: #000000;
  font-weight: lighter;
  font-size: 16px;
  font-weight: 300;
}
#MazPhoneNumberInput > div.select-country-container > div > label {
  color: #000;
}
#MazPhoneNumberInput-8_phone_number::placeholder,
#MazPhoneNumberInput-9_phone_number::placeholder {
  color: #000 !important;
  font-weight: normal !important;
  font-size: 16px !important;
  font-family: "Lato", sans-serif !important;
}
.broker-menu-form-inputs-submit {
  margin-top: 30px;
  display: flex;
  justify-content: center;
}
.broker-menu-form-inputs-submit button {
  max-width: 278px;
  width: 100%;
  background: #e94560;
  color: #fff;
  height: 60px;
  font-size: 21px;
  border-radius: 50px;
}

.errorFromServer p {
  text-align: center;
  color: #e94560;
  margin-top: 20px;
}
.broker-menu-form {
  max-width: 610px;
  width: 100%;
  margin: 0 auto;
}
.broker-menu-form-broker-img img {
  margin: 0 auto;
  margin-bottom: 15px;
}

.broker-menu-form-header h3 {
  text-align: center;
  font-size: 38px;
  font-weight: bold;
}

.broker-menu-form-header-sub p {
  font-size: 21px;
  text-align: center;
  max-width: 450px;
  width: 100%;
  margin: 0 auto;
  margin-top: 15px;
}
.broker-menu-form-inputs-input {
  margin-top: 20px;
}
.broker-menu-form-inputs-firstName-lastName input {
  border: 1px solid #dedcdc;
  background: none;
  outline: none;
  height: 50px;
  width: 290px;
  padding-left: 20px;
  font-size: 18px;
  border-radius: 6px !important;
}
.broker-menu-form-inputs-email {
  position: relative;
}
.broker-menu-form-inputs-phone {
  position: relative;
}

.broker-menu-form-inputs-email input {
  width: 100%;
  height: 50px;
  border: 1px solid #dedcdc;
  margin-top: 20px;
  padding-left: 20px;
  font-size: 18px;
  outline: none;
  border-radius: 6px;
}

.broker-menu-form-inputs-firstName-lastName {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 20px;
}

.broker-menu-form-inputs-firstName-lastName-error,
.broker-menu-form-inputs-email-error {
  position: absolute;
  bottom: -18px;
  color: #e94560;
}

.broker-menu-form-inputs-phone-error {
  position: absolute;
  bottom: -28px;
  color: #e94560;
}

.country-selector__input,
.input-tel__input {
  height: 50px !important;
}

.input-tel__input {
  /* width: 90% !important; */
}

#MazPhoneNumberInput {
  margin-top: 20px;
}
.broker-name-from-state {
  display: flex;
  justify-content: center;
}
@media (max-width: 1200px) {
  .loader-img {
    display: flex;
    justify-content: center;
  }
  .loader-img img {
    width: 300px;
    margin: 0 auto;
  }
}
.broker-menus-register-content-form-container-blocks-img-mb {
  display: none;
}
.broker-menus-register-logo-mb {
  display: none;
}
.broker-menus-register {
  padding-top: 47px;
  padding-bottom: 40px;
}
.broker-menus-register-content-form-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  margin-top: 80px;

  padding: 50px;
  box-shadow: 0 0 20px 2px rgba(0, 0, 0, 0.1);
  background: #fff;
  border-radius: 8px;
}

.broker-menus-register-content-form-container-blocks-img {
  position: absolute;
  top: -30px;
  left: -70px;
  z-index: -1;
}

.broker-menus-register-content-form-container-blocks-img2 {
  position: absolute;
  right: -120px;
  bottom: -50px;
  z-index: -1;
}

.broker-menus-register-content-form-left-side img {
  max-width: 480px;
  width: 100%;
}

.broker-menus-register-content-form-left-side {
  margin-right: 50px;
}

.broker-menus-register-content-form-right-side {
  max-width: 600px;
  width: 100%;
}

.broker-menus-register-content-form-right-side-logo {
  display: flex;
  justify-content: center;
  margin-bottom: 10px;
}

.broker-menus-register-content-form-right-side-content h3 {
  text-align: center;
  font-size: 38px;
  font-weight: 900;
  margin-bottom: 10px;
}

.broker-menus-register-content-form-right-side-content p {
  font-size: 21px;
  text-align: center;
}

@media (max-width: 1200px) {
  .broker-menus-register-content-form-container {
    flex-direction: column;
  }

  .broker-menus-register-logo {
    display: flex;
    justify-content: center;
  }

  .broker-menus-register {
    padding-top: 35px;
  }

  .broker-menus-register-content-form-container {
    max-width: 786px;
    width: 100%;
    margin: 0 auto;
    margin-top: 80px;
  }

  .broker-menus-register-content-form-left-side {
    margin: 0;
  }

  .broker-menus-register-content-form-left-side img {
    max-width: 430px;
    width: 100%;
    margin-bottom: 45px;
  }

  .broker-menus-register-content-form-container-blocks-img2 {
    right: -53px;
  }
}
@media (max-width: 860px) {
  .broker-menu-form-inputs-firstName-lastName-error.firstName {
    bottom: 52px;
  }
  .broker-menu-form-inputs-firstName-lastName {
    flex-wrap: wrap;
  }
  .broker-menu-form-inputs-firstName-lastName input {
    width: 100%;
  }
  .broker-menu-form-inputs-firstName-lastName input:nth-child(1) {
    margin-bottom: 20px;
  }

  .broker-menu-form-inputs-firstName-lastName-error.lastName {
    left: 0;
  }
}

@media (max-width: 768px) {
  .broker-menus-register-content-form-container {
    max-width: 608px;
  }

  .broker-menus-register-content-form-container-blocks-img2 {
    right: -40px;
  }
  .broker-menus-register-content-form-container-blocks-img {
    left: -50px;
  }

  .broker-menus-register-content-form-right-side-content h3 {
    font-size: 26px;
  }

  .broker-menus-register-content-form-right-side-content p {
    font-size: 18px;
  }
}
@media (max-width: 500px) {
  .broker-menus-register-content-form-container {
    max-width: 460px;
  }
  .broker-menus-register-content-form-left-side img {
    max-width: 243px;
  }

  .broker-menus-register-content-form-container {
    padding: 20px;
  }

  .broker-menus-register-logo-mb {
    display: block;
  }
  .broker-menus-register-logo-desktop {
    display: none;
  }

  .broker-menus-register-content-form-container-blocks-img-mb {
    display: block;
  }
  .broker-menus-register-content-form-container-blocks-img-desktop {
    display: none;
  }

  .broker-menus-register-logo {
    justify-content: flex-start;
  }

  .broker-menus-register-content-form-container {
    margin-top: 65px;
  }

  .broker-menus-register-content-form-container-blocks-img2 {
    right: -10px;
  }
  .broker-menus-register-content-form-container-blocks-img {
    left: -15px;
  }

  .broker-menus-register-content-form-right-side-content p {
    font-size: 16px;
  }
}

@media (max-width: 400px) {
  .broker-menu-form-inputs-firstName-lastName-error,
  .broker-menu-form-inputs-email-error {
    font-size: 14px;
  }

  .broker-menu-form-inputs-phone-error {
    font-size: 14px;
  }
}
@media (max-width: 350px) {
  .broker-menu-form-inputs-firstName-lastName-error,
  .broker-menu-form-inputs-email-error {
    font-size: 12px;
  }

  .broker-menu-form-inputs-phone-error {
    font-size: 13px;
  }
}
</style>